import React from 'react'

import Layout from '@components/layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReferenceComponent from '@components/references/reference.component'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import LearnMoreCardComponent from '@components/learn-more-card/learn-more-card.component'
import SiteLink from '@components/SiteLink'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import OffsetAnchor from '@components/OffsetAnchor'
import PediatricFacesOfCataplexyComponent from '@components/Pediatric/ped-faces-of-cataplexy/pediatric-faces-of-cataplexy'

import iosHelp from '@assets/images/icons/ios-help-circle-outline.svg'

import { trackGa } from '@components/GoogleTracking'

import './pediatric-symptoms.scss'


const PediatricSymptoms = ({ location }) => {

  const learnMoreContent = {
    className: 'learn-more-wrapper-gray',
    additionalStyles: 'learn-more-content-link',
    title: 'Need to learn more about the 5 main symptoms of narcolepsy?',
    linkLabel: 'Look deeper',
    link: '/screening-and-diagnosis/recognizing-symptoms/',
    tracking: 'pediatric symptoms, click, identifying narcolepsy - pediatric symptoms'
  }

  const askingAboutCataplexy = {
    icon: iosHelp,
    title: (
      <span>
        Asking patients and caregivers About Cataplexy<sup className="asking-about-cataplexy-sup">3,7-9</sup>
      </span>
    ),
    content: (
      <div>
        <span>
          How often does your child experience drooping or loose/weak muscles when he or she is laughing or expressing
          intense emotions, such as when tickled?
        </span>
        <span className="learn-more-content-padding">
          Do you ever notice eyelid drooping, mouth opening, or tongue protruding for no apparent reason?
        </span>
      </div>
    )
  }

  const hallucinations = {
    icon: iosHelp,
    title: (
      <span>
        Asking patients and caregivers About Hallucinations<sup>2,18-20</sup>
      </span>
    ),
    content: (
      <div>
        <span>How often does your child describe vivid dreamlike experiences?</span>
        <span className="learn-more-content-padding">
          How often does your child see or hear things that are not really there when falling asleep or waking up?
        </span>
      </div>
    )
  }

  const eds = {
    icon: iosHelp,
    title: (
      <span>
        {' '}
        Asking patients and caregivers About Excessive Daytime Sleepiness<sup>18,20</sup>
      </span>
    ),
    content: (
      <div>
        <span>Is your child very difficult to wake up in the morning?</span>
        <span className="learn-more-content-padding">Is your child constantly tired or sleepy during the day?</span>
        <span className="learn-more-content-padding">
          Does your child take a nap during the day? If so, how long does it last and is it refreshing?
        </span>
        <span className="learn-more-content-padding">Have you noticed any hyperactivity or difficulty focusing?</span>
      </div>
    )
  }

  const sleepParalysis = {
    icon: iosHelp,
    title: (
      <span>
        Asking patients and caregivers About Sleep Paralysis<sup>16,21</sup>
      </span>
    ),
    content: <div>How often does your child feel unable to move or speak when falling asleep or waking up?</div>
  }

  const sleepDisruption = {
    icon: iosHelp,
    title: (
      <span>
        Asking patients and caregivers About Sleep Disruption<sup>18,20</sup>
      </span>
    ),
    content: (
      <div>
        <span>Is your child very difficult to wake up in the morning?</span>
        <span className="learn-more-content-padding">
          How many times does your child wake up during the night? For how long?
        </span>
      </div>
    )
  }

  const references = [
    {
      content:
        'Maski K, Steinhart E, Williams D, et al. Listening to the patient voice in narcolepsy: diagnostic delay, disease burden, and treatment efficacy.',
      source: 'J Clin Sleep Med.',
      info: '2017;13(3):419-425.'
    },
    {
      content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In:',
      source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
      info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
    },
    {
      content:
        'Postiglione E, Antelmi E, Pizza F, Lecendreux M, Dauvilliers Y, Plazzi G. The clinical spectrum of childhood narcolepsy.',
      source: 'Sleep Med Rev.',
      info: '2018;38:70-85.'
    },
    {
      content: 'Pelayo R, Lopes MC. Narcolepsy. In: Lee-Chiong TL, ed.',
      source: 'Sleep: A Comprehensive Handbook.',
      info: 'Hoboken, NJ: Wiley and Sons, Inc.; 2006:145-149.'
    },
    {
      content: 'Ahmed I, Thorpy M. Clinical features, diagnosis and treatment of narcolepsy.',
      source: 'Clin Chest Med.',
      info: '2010;31(2):371-381.'
    },
    {
      content:
        'National Institute of Neurological Disorders and Stroke. Narcolepsy fact sheet. NIH Publication No. 17-1637. https://www.ninds.gov/Disorders/Patient-Caregiver-Education/fact-Sheets/Narcolepsy-Fact-Sheet. Accessed November 10, 2020.'
    },
    {
      content:
        'Plazzi G, Pizza F, Palaia V, et al. Complex movement disorders at disease onset in childhood narcolepsy with cataplexy.',
      source: 'Brain.',
      info: '2011;134(pt 12): 3480-3492.'
    },
    {
      content: 'Rocca FL, Pizza F, Ricci E, Plazzi G. Narcolepsy during childhood: an update.',
      source: 'Neuropediatrics.',
      info: '2015;46(3):181-198.'
    },
    {
      content:
        'Pillen S, Pizza F, Dhondt K, Scammell TE, Overeem S. Cataplexy and its mimics: clinical recognition and management.',
      source: 'Curr Treat Options Neurol.',
      info: '2017;19(6):23.'
    },
    {
      content:
        'Thorpy M, Morse AM. Reducing the clinical and socioeconomic burden of narcolepsy by earlier diagnosis and effective treatment.',
      source: 'Sleep Med Clin.',
      info: '2017;12(1):61-71.'
    },
    {
      content:
        'Pizza F, Franceschini C, Peltola H, et al. Clinical and polysomnographic course of childhood narcolepsy with cataplexy.',
      source: 'Brain.',
      info: '2013;136(pt 12):3787-3795.'
    },
    {
      content: 'Broughton WA, Broughton RJ. Psychosocial impact of narcolepsy.',
      source: 'Sleep.',
      info: '1994;17(8 suppl):S45-S49.'
    },
    {
      content: 'Kauta SR, Marcus CL. Cases of pediatric narcolepsy after misdiagnoses.',
      source: 'Pediatr Neurol.',
      info: '2012;47(5):362-365.'
    },
    {
      content: 'Serra L, Montagna P, Mignot E, Lugaresi E, Plazzi G. Cataplexy features in childhood narcolepsy.',
      source: 'Mov Disord.',
      info: '2008;23(6):858-865.'
    },
    {
      content:
        'Maski K, Owens JA. Insomnia, parasomnias, and narcolepsy in children: clinical features, diagnosis, and management.',
      source: 'Lancet Neurol.',
      info: '2016;15(11):1170-1181.'
    },
    {
      content: 'Babiker MO, Prasad M. Narcolepsy in children: a diagnostic and management approach.',
      source: 'Pediatr Neurol.',
      info: '2015;52(6):557-565.'
    },
    {
      content:
        'Jardri R, Bartels-Velthuis AA, Debbané M, et al. From phenomenology to neurophysiological understanding of hallucinations in children and adolescents.',
      source: 'Schizophr Bull.',
      info: '2014;40(suppl 4):S221-S232.'
    },
    {
      content: 'Luginbuehl M, Kohler WC. Screening and evaluation of sleep disorders in children and adolescents.',
      source: 'Child Adolesc Psychiatric Clin N Am.',
      info: '2009;18(4):825-838.'
    },
    { 
      content: 'Nevsimalova S. Narcolepsy in childhood.', 
      source: 'Sleep Med Rev.', 
      info: '2009;13(2):169-180.' 
    },
    {
      content: 'Nevsimalova S. The diagnosis and treatment of pediatric narcolepsy.',
      source: 'Curr Neurol Neurosci Rep.',
      info: '2014;14(8):469.'
    },
    {
      content:
        'Stores G, Montgomery P, Wiggs L. The psychosocial problems of children with narcolepsy and those with excessive daytime sleepiness of uncertain origin.',
      source: 'Pediatrics.',
      info: '2006;118(4):e1116-e1123.'
    },
    {
      content: 'Roth T, Dauvilliers Y, Mignot E, et al. Disrupted nighttime sleep in narcolepsy.',
      source: 'J Clin Sleep Med.',
      info: '2013;9(9):955-965.'
    },
    {
      content: 'Sturzenegger C, Bassetti CL. The clinical spectrum of narcolepsy with cataplexy: a reappraisal.',
      source: 'J Sleep Res.',
      info: '2004;13(4):395-406.'
    }
  ]

  return (
    <Layout addedClass='page-pediatric-symptoms' location={location}>
      <div className="pediatric-symptoms-page-header" id="Top">
        <div className="primary-container">
          <div className="pediatric-symptoms-header-text">
            <h1>Recognize Narcolepsy in Pediatric Patients</h1>
            <p>
              <span className="semi-bold">
                Symptoms of narcolepsy may present differently in pediatric patients.<sup>1-3</sup>
              </span>{' '}
              The 5 main symptoms of narcolepsy are referred to by the acronym CHESS (
              <AnchorLink href="#Cataplexy" onClick={() => trackGa('recognize symptoms peds, click, cataplexy anchor')}>
                <b>C</b>ataplexy
              </AnchorLink>,{' '}
              <AnchorLink
                href="#Hallucinations"
                onClick={() => trackGa('recognize symptoms peds, click, hallucinations anchor')}
              >
                <b>H</b>allucinations
              </AnchorLink>,{' '}
              <AnchorLink
                href="#EDS"
                onClick={() => trackGa('recognize symptoms peds, click, excessive daytime sleepiness anchor')}
              >
                <b>E</b>xcessive daytime sleepiness
              </AnchorLink>,{' '}
              <AnchorLink
                href="#SleepParalysis"
                onClick={() => trackGa('recognize symptoms peds, click, sleep paralysis anchor')}
              >
                <b>S</b>leep paralysis
              </AnchorLink>,{' '}
              <AnchorLink
                href="#SleepDisruption"
                onClick={() => trackGa('recognize symptoms peds, click, sleep disruption anchor')}
              >
                <b>S</b>leep disruption
              </AnchorLink>).<sup>4</sup>{' '}
              <span className="semi-bold">
                All patients with narcolepsy experience excessive daytime sleepiness<sup>2</sup>; however, not all
                patients with narcolepsy experience all of the other 4 symptoms.<sup>2,5,6</sup>
              </span>
            </p>
          </div>
        </div>
        <div
          className="pediatric-symptoms-criteria-image-container"
          role="image"
          aria-label="Recognize narcolepsy in pediatric patients banner"
        />
      </div>
      <div className="primary-container">
        <div className="pediatric-symptoms-header-content">
          <p>
            <h3>
              As children become adolescents and adults, the symptoms of narcolepsy may manifest differently and change
              over time.<sup>1-3</sup>
            </h3>
          </p>
          <ul className="burden-symptom-list">
            <li>
              <span>
                The possibility of an evolving disorder, with cataplexy developing over time, should be considered in
                all pediatric patients with narcolepsy type 2.<sup>2</sup>
              </span>
            </li>
            <li>
              <span>
                As a child with narcolepsy type 1 ages, cataplexy develops into the more typical form observed in
                adults.<sup>3,7-10</sup>
              </span>
            </li>
            <li>
              <span>
                Hypnagogic/hypnopompic hallucinations and sleep paralysis may be age-dependent phenomena that slowly
                appear and progress along the disease course.<sup>2,8,11</sup>
              </span>
            </li>
          </ul>
          <p className="ped-symtoms-p-width">
            Ongoing monitoring for changes in symptom presentation and comorbid medical and psychiatric
            conditions is important when managing patients with narcolepsy.<sup>10,12,13</sup>
          </p>
        </div>
      </div>
      <div className="background-white">
        <section className="section-gradient" />
      </div>
      <div className="primary-container content-wrapper">
        <div className="content-wrapper-header">
          <h2 className="section-header">Narcolepsy Symptoms in Pediatric Patients </h2>
          <LearnMoreComponent learnMoreContent={learnMoreContent} />
        </div>
        <div className="primary-card symptom-card cataplexy-container">
          <OffsetAnchor id="Cataplexy" />
          <div className="content-row">
            <div className="symptom-card-column text-container cataplexy-symptom-card card-content">
              <h2 className="section-header">Cataplexy</h2>
              <h3>Cataplexy may manifest differently in pediatric patients.</h3>
              <p>
                Cataplexy typically presents at the same time as or within a year from onset of excessive daytime
                sleepiness<sup>2,7,14</sup> and can have a distinct presentation in young children at onset, by manifesting
                as a complex movement disorder.<sup>2,3,7-9</sup> In some cases, onset of cataplexy may be delayed for
                years or decades.<sup>2</sup> Therefore, the potential for cataplexy to present later in the disease
                course should be considered in all pediatric patients with narcolepsy type 2.<sup>2</sup>
              </p>
            </div>
            <div className="symptom-card-column learn-more-container desktop-only">
              <LearnMoreCardComponent learnMoreCard={askingAboutCataplexy} />
            </div>
          </div>
          <div className="symptom-card-column text-container">
            <h3>Recognizing Cataplexy</h3>
            <ul>
              <li>
                <span>
                  Close to disease onset, cataplexy may manifest as a complex movement disorder, which includes<sup>
                    3,7-9,14,15
                  </sup>:
                </span>
                <ul className="dashed-seperate">
                  <li className="top-spacer">
                    <span>
                      “Negative” (hypotonic) motor features, such as head drop, persistent facial hypotonia, persistent
                      eyelid droop, and tongue protrusion
                    </span>
                  </li>
                  <li>
                    <span>
                      “Active” movement abnormalities or dyskinesia, such as raising eyebrows, repetitive mouth opening,
                      lip chewing, grimaces, and tongue thrusting
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  Close to disease onset, children may present with cataplectic facies,<sup>14</sup> a localized version
                  of status cataplecticus, which<sup>7,9,14</sup>:
                </span>
                <ul className="dashed-seperate">
                  <li className="top-spacer">
                    <span>Affects facial muscles, often without any clear emotional triggers</span>
                  </li>
                  <li>
                    <span>Manifests as repetitive mouth opening, tongue protrusion, and drooping eyelids</span>
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  As children age, cataplexy presentation changes into the classical forms observed in adults.<sup>7-10</sup>
                </span>
              </li>
            </ul>
            <div style={{margin: "20px 0 10px 0", fontWeight: "bold", fontSize: "18px", color: "rgb(51, 51, 51)", fontFamily: "Open Sans"}}>
              Watch the videos below to learn more about cataplexy through real pediatric patients with narcolepsy.
            </div>
            <div style={{fontSize: "18px", color: "#4d4d4d", fontFamily: "Open Sans", fontWeight: "300"}}>
              These descriptions and depictions of the various possible presentations of cataplexy may help you screen your own patients for cataplexy.
            </div>
          </div>
          <PediatricFacesOfCataplexyComponent />
          <div className="faces-of-cataplexy-disclaimer">
            This video reflects the personal story and experiences of this actual patient and her caregiver. Individual results and experiences will vary. The participants have been compensated by Jazz Pharmaceuticals for their work on this video.
          </div>
          <div className="symptom-card-column learn-more-container mobile-only">
            <LearnMoreCardComponent learnMoreCard={askingAboutCataplexy} />
          </div>
          <div className="back-to-top-wrapper">
            <AnchorLink className="back-to-top" href="#Top">
              Back to top
              <FontAwesomeIcon icon="angle-up" />
            </AnchorLink>
          </div>
        </div>
        <div className="primary-card symptom-card">
          <OffsetAnchor id="Hallucinations" />
          <div className="symptom-card-row">
            <h2 className="section-header">Hallucinations (Hypnagogic and Hypnopompic)</h2>
            <p>
              Hypnagogic (occurring at sleep onset) and hypnopompic (occurring while awakening) hallucinations include
              primary visual hallucinations, such as seeing shadowy figures, animals, people, and formed shapes in the
              room.<sup>15,16</sup> Nearly 2/3 of patients report experiencing hypnagogic/hypnopompic hallucinations.<sup
              >
                16
              </sup>{' '}
              Therefore, uncovering the presence of these hallucinations can be helpful when considering a diagnosis of narcolepsy.
            </p>
          </div>
          <div className="content-row">
            <div className="symptom-card-column text-container card-content">
              <h3>Recognizing Hallucinations</h3>
              <ul>
                <li>
                  <span>
                    For younger children, hallucinations can be scary and, in some cases, may cause them to fear going to
                    bed.<sup>16,17</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Young children may be unable to distinguish hallucinations from reality, making obtaining an accurate
                    history of this symptom difficult.<sup>3</sup>
                  </span>
                </li>
              </ul>
            </div>
            <div className="symptom-card-column learn-more-container">
              <LearnMoreCardComponent learnMoreCard={hallucinations} />
            </div>
          </div>
          <div className="back-to-top-wrapper">
            <AnchorLink className="back-to-top" href="#Top">
              Back to top
              <FontAwesomeIcon icon="angle-up" />
            </AnchorLink>
          </div>
        </div>
        <div className="primary-card symptom-card">
          <OffsetAnchor id="EDS" />
          <div className="symptom-card-row">
            <h2 className="section-header">Excessive Daytime Sleepiness </h2>
            <h3>
              Excessive daytime sleepiness may present differently in children.<sup>3,8</sup>
            </h3>
            <p>
              Excessive daytime sleepiness is the cardinal symptom of narcolepsy.<sup>2</sup> All children and
              adolescents with narcolepsy experience excessive daytime sleepiness; however, they may not experience all
              of the other 4 symptoms.<sup>2,6</sup> In children, excessive daytime sleepiness may be difficult to
              identify because its presentation can vary from that of adults.<sup>3,8</sup> Because of its unusual
              presentation in children, excessive daytime sleepiness is often mislabeled or overlooked as laziness or
              inattention.<sup>3</sup> When associated with irritability and hyperactivity, it is often misdiagnosed as
              another behavioral condition, such as ADHD.<sup>1,3</sup>{' '}
            </p>
          </div>
          <div className="content-row">
            <div className="symptom-card-column text-container card-content">
              <h3>Recognizing Excessive Daytime Sleepiness </h3>
              <ul>
                <li>
                  <span>
                    Younger children may present with elongation of daytime naps and nighttime sleep, with earlier
                    bedtimes.<sup>2,3,8</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Older children may restart regular daytime napping after naps have been discontinued.<sup>2,8,15</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Nap durations are generally longer compared with adults and may be unrefreshing.<sup>8,20</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Confusional arousals, or awakening without becoming fully aware,<sup>15</sup> lasting up to 15 to 30
                    minutes, can occur upon awakening in the morning or from naps.<sup>20</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Children and adolescents with excessive daytime sleepiness may present as aggressive, irritable, or
                    hyperactive in an attempt to cope with or counteract sleepiness.<sup>3,8</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Excessive daytime sleepiness is often not recognized as abnormal until cataplexy appears.<sup>20</sup>
                  </span>
                </li>
              </ul>
            </div>
            <div className="symptom-card-column learn-more-container">
              <LearnMoreCardComponent learnMoreCard={eds} />
            </div>
          </div>
          <div className="back-to-top-wrapper">
            <AnchorLink className="back-to-top" href="#Top">
              Back to top
              <FontAwesomeIcon icon="angle-up" />
            </AnchorLink>
          </div>
        </div>
        <div className="primary-card symptom-card">
          <OffsetAnchor id="SleepParalysis" />
          <div className="symptom-card-row">
            <h2 className="section-header">Sleep Paralysis</h2>
            <p>
              Because children may have difficulty describing sleep paralysis and appropriately reporting the inability
              to move when falling asleep or waking up, it may be difficult to confirm sleep paralysis in children.<sup>
                2,8
              </sup>
            </p>
          </div>
          <div className="content-row">
            <div className="symptom-card-column text-container card-content">
              <h3>Recognizing Sleep Paralysis</h3>
              <ul>
                <li>
                  <span>
                    In addition to being unable to move when falling asleep or waking up, children with sleep paralysis
                    may describe difficulty breathing, despite normal respiratory function.<sup>16,21 </sup>
                  </span>
                </li>
                <li>
                  <span>
                    Sleep paralysis usually lasts for a few seconds to a few minutes and can end spontaneously or when the
                    child is touched, shaken, or spoken to.<sup>5,16</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Sleep paralysis may be an age-dependent phenomenon, as its appearance slowly progresses in young
                    patients along the disease course.<sup>8,11</sup>
                  </span>
                </li>
              </ul>
            </div>
            <div className="symptom-card-column learn-more-container">
              <LearnMoreCardComponent learnMoreCard={sleepParalysis} />
            </div>
          </div>
          <div className="back-to-top-wrapper">
            <AnchorLink className="back-to-top" href="#Top">
              Back to top
              <FontAwesomeIcon icon="angle-up" />
            </AnchorLink>
          </div>
        </div>
        <div className="primary-card symptom-card">
          <OffsetAnchor id="SleepDisruption" />
          <div className="symptom-card-row">
            <h2 className="section-header">Sleep Disruption</h2>
            <p>
              Children and adolescents with narcolepsy may have difficulty maintaining sleep,<sup>2,14,16,22</sup> and they
              may have frequent nighttime awakenings.<sup>2,22,23</sup>
            </p>
          </div>
          <div className="content-row">
            <div className="symptom-card-column text-container card-content">
              <h3>Recognizing Sleep Disruption</h3>
              <ul>
                <li>
                  <span>
                    In children and adolescents, disrupted nighttime sleep may impact cognition, emotional regulation, and
                    neurobehavioral functioning.<sup>15</sup>
                  </span>
                </li>
                <li>
                  <span>
                    Disrupted nighttime sleep may not be reported by pediatric patients or their caregivers unless
                    targeted questions are asked.<sup>20</sup>
                  </span>
                </li>
              </ul>
            </div>
            <div className="symptom-card-column learn-more-container">
              <LearnMoreCardComponent learnMoreCard={sleepDisruption} />
            </div>
          </div>
          <div className="back-to-top-wrapper">
            <AnchorLink className="back-to-top" href="#Top">
              Back to top
              <FontAwesomeIcon icon="angle-up" />
            </AnchorLink>
          </div>
        </div>
        <SiteLink 
          className="cta-tile-button btn-primary"
          to="/pediatric-patients/clinical-interview/"
          tracking="pediatric symptoms, click, clinical interview - pediatric symptoms"
          liveRampTracking={true}
        >
          <div className="cta-tile-button-text">
            Read Next: <span style={{ textTransform: 'none', fontWeight: 'normal' }}>Clinical Interview</span>&nbsp;&nbsp;
          </div>
          <FontAwesomeIcon className="arrow-right" icon="angle-right" />
        </SiteLink>
      </div>
      
      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default PediatricSymptoms
