import React, { Component } from 'react'
import update from 'immutability-helper'
import Masonry from 'react-masonry-component'

import { masonryOptions, IMG_STATE } from './faces.util'
import { pedVideoLists } from '@components/video-library/videos'
import PediatricFaceComponent from './pediatric-face-component'

import './pediatric-faces-of-cataplexy.scss'

class PediatricFacesOfCataplexyComponent extends Component {
  constructor(props) {
    super(props)
    this.currentVideoRef = {}
    this.state = {
      videos: pedVideoLists.facesOfCataplexy.map((video, index) => {
        //add state specific information to videos
        video.state = IMG_STATE.NORMAL
        video.img = {
          reg: require(`@assets/images/faces/pediatrics/pediatric-faces-of-cataplexy-video-${index + 1}.jpg`)
        }

        if (index == 0) {
          // Bug NL-564: First video should be the larger size, in focus so the user knows where
          // to start.
          video.state = IMG_STATE.BIG_IMG
        }

        return video
      }),
      isMobile: false
    }
  }

  updateDimensions() {
    if (window.innerWidth < 994) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render() {
    const { isMobile } = this.state

    const faceImages = this.state.videos.map((video, index) => {
      return (
        <PediatricFaceComponent
          video={video}
          index={index}
          key={index}
          onMouseEnter={() => {
            this.onMouseEnter(index)
          }}
          onMouseLeave={() => {
            this.onMouseLeave(index)
          }}
          onClick={() => {
            this.playVideo(index)
          }}
        />
      )
    })
    return (
      <div className="pediatric-faces-of-cataplexy">
        <div className="video-wrapper">
          {!isMobile && (
            <div id="faces" ref={element => (this.videoDiv = element)}>
              <Masonry
                className={'faces'}
                elementType={'div'}
                options={masonryOptions}
                ref={el => (this.masonry = this.masonry || el.masonry)}
              >
                {faceImages}
              </Masonry>
            </div>
          )}
          {isMobile && <div className="faces mobile">{faceImages}</div>}
        </div>
      </div>
    )
  }

  onMouseEnter(videoPos) {
    if (this.state.videos[videoPos].state === IMG_STATE.NORMAL) {
      this.setState(previousState => {
        return {
          videos: update(previousState.videos, {
            [videoPos]: { state: { $set: IMG_STATE.HOVER } }
          })
        }
      })
    }
  }

  onMouseLeave(videoPos) {
    if (this.state.videos[videoPos].state === IMG_STATE.HOVER) {
      this.setState(previousState => {
        return {
          videos: update(previousState.videos, {
            [videoPos]: { state: { $set: IMG_STATE.NORMAL } }
          })
        }
      })
    }
  }

  playVideo(videoPos) {
    this.setState(previousState => {
      let updatedVideos = {}
      previousState.videos.forEach((video, index) => {
        updatedVideos[index] = {
          state: { $set: IMG_STATE.NORMAL }
        }
      })
      let newState = IMG_STATE.VIDEO
      if (!previousState.videos[videoPos].videoId) newState = IMG_STATE.BIG_IMG
      updatedVideos = {
        ...updatedVideos,
        [videoPos]: { state: { $set: newState } }
      }
      return {
        videos: update(previousState.videos, updatedVideos)
      }
    })
  }
}

export default PediatricFacesOfCataplexyComponent
