import React from 'react'
import PropTypes from 'prop-types'

import './learn-more-card.component.scss'

const LearnMoreCardComponent = props => {
  return (
    <div className="learn-more-card-wrapper">
      <div className="learn-more-card-icon">
        <img src={props.learnMoreCard.icon} />
      </div>
      <div className="learn-more-card-text">
        <div className="learn-more-card-title">{props.learnMoreCard.title}</div>
        <div className="learn-more-card-content">{props.learnMoreCard.content.props.children}</div>
      </div>
    </div>
  )
}

LearnMoreCardComponent.propTypes = {
  learnMoreCard: PropTypes.object.isRequired
}

export default LearnMoreCardComponent
